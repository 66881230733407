@tailwind base;
@tailwind components;
@tailwind utilities;

/* smooth scroll */
html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

/* global overwrite */
b,
strong {
  font-weight: bold;
}

/* text styling */
.text-bordered {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: inherit;

  &-sm {
    -webkit-text-stroke-width: 1px;
  }
}

/* field styling  */

input {
  &.remove-styling {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

/* slick styling */
.hero-slider {
  .slick {
    line-height: 1;
    // slider nav
    &-arrow {
      @apply w-12 h-12 rounded-full border-2 border-white absolute bottom-6 z-50 bg-center bg-no-repeat overflow-hidden opacity-30 duration-300 transform scale-75 lg:scale-100 md:top-1/2 md:bottom-auto md:-mt-7 text-white;
      text-indent: 4rem;

      svg {
        @apply absolute left-1/2 top-1/2 -mt-3 -ml-3;
      }
    }

    &-prev {
      @apply left-2 lg:left-8;
    }

    &-next {
      @apply right-2 lg:right-8;
    }

    &-slide {
      @apply duration-300;
      opacity: 0.5;
    }

    &-current {
      opacity: 1;
    }

    &-dots {
      @apply absolute bottom-6 left-0 w-full text-center z-50;

      li {
        @apply inline-block px-1;

        button {
          @apply w-4 h-4 border border-grey-light-2 bg-transparent duration-300 shadow-border-inner-black rounded-full overflow-hidden inline-block;
          text-indent: 2rem;
        }

        &.slick-active {
          button {
            @apply bg-white border-white;
          }
        }
      }
    }
  }

  &.slick-slider {
    &:hover {
      .slick-arrow {
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.product-thumb {
  &-slider {
    .slick {
      &-slide {
        .product-thumb {
          @apply border border-grey-light-4;
        }
      }

      &-current {
        .product-thumb {
          @apply bg-blue-dark border-blue-dark;
        }
      }
    }

    &-lg {
      position: relative;
      .slick {
        &-slide {
          @apply opacity-30;
        }

        &-list {
          @apply bg-grey-light-4 bg-opacity-40;
        }

        &-track {
          @apply mx-auto;
        }

        &-current {
          @apply opacity-100;
        }

        &-arrow {
          @apply absolute top-0 h-full z-20 w-14 overflow-hidden indent-24 bg-black bg-opacity-5 transition-all duration-300;

          &::after {
            content: "";
            background-image: url(./images/chevron-right-white.svg);
            @apply block w-6 h-6 bg-no-repeat bg-center absolute left-1/2 top-1/2 -mt-3 -ml-3;
          }

          &:hover {
            @apply bg-opacity-20;
          }

          &.slick-disabled {
            @apply opacity-40 pointer-events-none;
          }
        }

        &-prev {
          @apply left-0 rotate-180;
        }

        &-next {
          @apply right-0;
        }
      }
    }
  }

  &-dots {
    @apply text-center pt-4 absolute bottom-0 w-full;

    li {
      @apply inline;

      button {
        @apply inline-block w-2 h-2 rounded-full overflow-hidden border-2 border-white mx-1;
      }

      &.slick-active {
        button {
          @apply bg-white border-white;
        }
      }
    }
  }
}

// phone field styling override
.phone-fld-grp {
  .react-tel-input {
    .form-control {
      @apply w-full text-base h-12 border border-grey-light-4;

      &.invalid-number {
        @apply bg-white border border-fatal;

        &:focus {
          @apply bg-white border-fatal;
        }
      }
    }

    .flag-dropdown {
      @apply border-r-0;

      &.invalid-number {
        @apply border-fatal;
      }

      &.open {
        @apply border-r-0 bg-transparent;
      }
    }

    .selected-flag {
      @apply pl-4 hover:bg-transparent;

      .arrow {
        display: none;
      }
    }
  }
}

// date picker style overwrite
.date-field {
  .react-date-picker {
    @apply flex w-full h-12 pl-4 pr-2 py-2 bg-white border border-grey-light-4 outline-none;

    &.has-error {
      @apply border-fatal;
    }

    &__wrapper {
      @apply border-0;
    }
  }
}

// stripe el
.has-error {
  @apply border-fatal;
}

// country select
.country-selector {
  div[class^="ReactFlagsSelect-module_flagsSelect"] {
    button {
      padding: 0;
      border: 0;

      span[class^="ReactFlagsSelect-module_label"] {
        display: none;
      }
    }
  }

  & > div {
    margin: 0;
    padding: 0;
  }

  #rfs-btn {
    height: 20px;
    padding-left: 4px;
    span {
      margin: 0;
      padding: 0;
    }
  }

  ul[class^="ReactFlagsSelect-module_selectOptions"] {
    overflow-x: hidden;
    right: -1.5rem;
    top: 1.5rem;
    left: auto;
    width: 220px;
    min-width: 100px;
    @apply border border-grey-light-4;
  }
}

.custom-store-banner {
  &.text-only {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    display: grid;
    place-items: center;
  }
  &.v1 {
    @apply relative min-h-500 max-w-screen-xl mx-auto flex flex-row justify-start items-center animate-fade-in overflow-hidden md:overflow-visible;

    .decor {
      @apply absolute z-10 bottom-14 md:bottom-6 -left-6 md:left-16 transform scale-75 md:scale-100;
    }

    .hero-text-content {
      @apply text-left uppercase text-white relative z-40 font-bold pl-6 md:pl-16 lg:pl-24;

      h2 {
        @apply text-2xl md:text-3xl;
      }
      h1 {
        @apply text-5xl md:text-5.5xl leading-none md:leading-tight;
      }
    }
    .styled-text {
      @apply text-white text-bordered block sm:inline-block;
    }
    .hero-p {
      @apply normal-case font-normal text-grey-light mb-6 md:mb-14 text-lg pr-4 md:pr-0;
    }
    .styled-white-text {
      @apply text-white;
    }
    .btn-wrapper {
      @apply grid grid-rows-2 md:grid-cols-3 gap-4 w-60 md:w-full leading-6;
    }
    .btn-stroke {
      @apply font-bold uppercase border-2 border-white text-center duration-300 bg-white text-white hover:bg-opacity-100 bg-opacity-0 hover:text-blue-dark w-full py-2.5 px-2.5;
    }
    .btn-solid {
      @apply font-bold uppercase border-2 border-white text-center duration-300 bg-white text-grey-dark hover:border-green-main w-full py-2.5 px-5 hover:bg-green-main hover:text-white;
    }
    .hero-imgs {
      @apply opacity-20 md:opacity-30 xl:opacity-100 flex items-center self-stretch;
    }
    .hero-bg {
      @apply absolute left-1/3 -ml-8 lg:ml-0 lg:left-1/2 top-0 z-10 h-full w-auto;
    }
    .hero-img {
      @apply absolute bottom-0 w-480 max-w-30r md:max-w-full lg:w-auto -ml-44 md:-ml-8 xl:ml-0 left-1/2 md:left-1/4 lg:left-43 z-30;
    }
    .hero-img2 {
      @apply opacity-50 sm:opacity-100 absolute mt-6 left-73 z-20;
    }
  }
}

.footer-banner {
  &.v1 {
    @apply relative max-w-screen-xl mx-auto flex flex-col lg:flex-row justify-center items-center font-bold;

    .banner-text {
      @apply text-white uppercase text-center lg:text-left py-6 lg:py-0 px-6 lg:pl-4 lg:pr-0;

      h2 {
        @apply text-xl xl:text-2xl;
      }

      h1 {
        @apply text-3xl xl:text-4.5xl leading-none;

        strong {
          @apply text-white text-bordered inline-block;
        }
      }

      p {
        @apply normal-case font-normal text-white px-4 lg:px-0 text-sm xl:text-lg;
      }
    }

    .banner-buttons {
      @apply text-white uppercase px-4 xl:px-8 grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 mb-6 lg:mb-0;

      .banner {
        &-btn-1 {
          @apply font-bold border-2 border-white text-center duration-300 bg-transparent text-white hover:bg-white hover:text-black px-2.5 py-2.5 w-48;
        }
        &-btn-2 {
          @apply font-bold uppercase border-2 text-grey-dark border-white text-center duration-300 bg-white hover:bg-green-main hover:border-green-main hover:text-white px-2.5 py-2.5 w-48;
        }
      }
    }

    .banner-img {
      @apply flex-grow-0 flex-shrink xl:flex-shrink-0 order-first lg:order-none mb-6 lg:mb-0;
    }
  }
}

.product-details {
  p:empty {
    display: none;
  }
  ul,
  ol {
    @apply ml-5;
  }
  ul {
    @apply list-disc;
  }
  ol {
    @apply list-decimal;
  }
}

// cart animation
.cart-updated {
  animation: cart-updated 1s ease-out 0s 3 normal;
}

@keyframes cart-updated {
  0% {
    box-shadow: 0 0 0 0 rgba(189, 43, 43, 0);
  }
  75% {
    box-shadow: 0 0 0 10px rgba(189, 43, 43, .5);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(189, 43, 43, 0);
  }
}
